
import $ from 'jquery'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.min.css'

class PressTeaser extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
  }

  resolveElements () {
    this.$slider = $('[data-slider-press]', this)
    this.$buttonNext = $('[data-slider-button="next"]', this)
    this.$buttonPrev = $('[data-slider-button="prev"]', this)

    console.log(this.$slider)
  }

  connectedCallback () {
    this.initSlider()
  }

  initSlider () {
    const config = {
      draggable: true,
      preventClicks: false,
      loop: true,
      preventClicksPropagation: false,
      touchMoveStopPropagation: true,
      touchReleaseOnEdges: true,
      slideClass:                   'swiper-slide',
      wrapperClass:                 'swiper-wrapper',
      speed: 500,
      navigation: {
        'nextEl': this.$buttonNext,
        'prevEl': this.$buttonPrev,
        'disabledClass':            'is-disabled',
        'hiddenClass':              'is-hidden'
      },
      'a11y': {
        'enabled': false
      },
      slidesPerView: 1,
      spaceBetween: 20,
      breakpointsInverse: true,
      breakpoints: {
        1024: {
          'spaceBetween': 50,
          'slidesPerView': 2
        },
        768: {
          'slidesPerView': 2,
          'spaceBetween': 25
        }
      }
    }

    this.swiper = new Swiper(this.$slider, config)
  }
}

window.customElements.define('flynt-press-teaser', PressTeaser, { extends: 'div' })
